<template>
  <div id="app">
    <router-view v-if="vxSignInToggle" id="nav" name="nav" />
    <router-view v-else id="nav" name="language" />
    <router-view v-if="vxSignInToggle" id="sidebar" name="sidebar" />
    <div class="gap"></div>
    <showOnlyEn v-if="onlyEnToggle"></showOnlyEn>
    <router-view id="frame" />
    <!-- modal -->
    <!-- token過期 -->
    <b-modal
      id="setCheckToken"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.error')"
    >
      <template v-slot:modal-header>
        <h5>{{ $t('modal.error') }}</h5>
        <button @click="backToSignInPage">X</button>
      </template>
      <h6>
        {{ $t('modal.tokenError') }}
      </h6>
      <template v-slot:modal-footer="{ close }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="close(backToSignInPage())"
        >
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- something wrong -->
    <b-modal
      id="setsomethingWrong"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.error')"
    >
      <h6>{{ $t('modal.somethingWrong') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- unauthorized -->
    <b-modal id="unauthorizedErrorToggle" centered :title="$t('modal.warning')">
      <h6>{{ $t('modal.unauthorized') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- account not exist -->
    <b-modal id="accountNotExistToggle" centered :title="$t('modal.warning')">
      <h6>{{ $t('modal.accountNotExist') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>

    <!-- change your headshoot -->
    <b-modal
      id="uploadImageToggle"
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <template v-slot:modal-header>
        <h5>{{ $t('modal.changeHead') }}</h5>
        <button @click="clearUploadImage">X</button>
      </template>
      <div class="uploadImageBox">
        <i v-if="changeImage" class="far fa-user"></i>
        <img
          @error="changeImage = true"
          v-else
          class="headShoot"
          :src="previewImage"
        />
      </div>

      <div class="controlBox">
        <label class="inputButton">
          <input
            @change="fnChooseHeadShot"
            class="disabledButton"
            type="file"
            accept="image/jpeg"
          />
          <p>{{ $t('modal.headshot.upload') }}</p>
        </label>
        <p :class="upLoadError ? 'red' : ''">
          {{ $t('modal.headshot.text') }}
        </p>
      </div>

      <template v-slot:modal-footer="{ close }">
        <m-button
          class="tableButton"
          raised
          dense
          @click="close(clearUploadImage())"
        >
          {{ $t('button.close') }}
        </m-button>
        <m-button
          class="cancel tableButtonUpdate"
          raised
          dense
          @click="fnUploadHeadShot"
          :disabled="!upLoadButtonToggle"
        >
          <p v-show="!loading">{{ $t('button.save') }}</p>
          <div class="loading" v-show="loading">
            <b-spinner small label="Loading..." :variant="'light'"></b-spinner>
          </div>
        </m-button>
      </template>
    </b-modal>

    <!-- 上傳圖片成功 -->
    <b-modal id="uploadImageSuccess" centered :title="$t('modal.success')">
      <h6>{{ $t('modal.headshot.success') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import 'bootstrap/dist/css/bootstrap.css';
  import 'bootstrap-vue/dist/bootstrap-vue.css';

  import imageCompression from 'browser-image-compression';
  import showOnlyEn from '@/components/showOnlyEn';
  import { APIuploadHeadshot } from './api';
  export default {
    components: {
      showOnlyEn,
    },
    data() {
      return {
        changeImage: false, // 判斷圖片是否要用預設圖，不用vuex中的headshotDefault原因為這邊的modal要有預覽圖功能
        upLoadButtonToggle: false,
        upLoadError: false, // 檔案或圖片不符合預期
        loading: false,
        previewImage: '', // modal頭貼、預覽圖
      };
    },
    watch: {
      // 監聽headshotImage，變化的話取代previewImage
      vxHeadshotImage: function () {
        this.previewImage = this.vxHeadshotImage;
        this.changeImage = false; // 上一個使用者為預設圖時，監聽切換變化，控制預設圖的開關也要變化
      },
    },
    methods: {
      backToSignInPage() {
        this.$bvModal.hide('setCheckToken');
        this.$store.dispatch('setAccountInformation', {
          accountLevel: 'Technician',
        });
        this.$store.dispatch('setAccount', '');
        this.$store.dispatch('setSignInToggle', false);
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('vuex');
        this.$router.push('/');
      },
      fnChooseHeadShot(e) {
        this.upLoadButtonToggle = true;
        this.upLoadError = false;
        let image = e.target.files[0];
        if (!image) {
          return;
        }

        if (image.type !== 'image/jpeg') {
          this.upLoadError = true;
          this.upLoadButtonToggle = false;
          return;
        }
        // if (image.size >= 102400) {
        //   alert("圖片檔案請小於100KB");
        //   return;
        // }

        let options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 256,
          useWebWorker: true,
        };
        imageCompression(image, options).then(compressedFile => {
          let reader = new FileReader();
          reader.readAsDataURL(compressedFile);

          reader.onload = e => {
            this.defaultImage = false;
            this.changeImage = false;
            this.previewImage = e.target.result;
            let imager = new Image();
            imager.src = e.target.result;

            // imager.onload = e => {
            //   console.log("type :" + image.type);
            //   console.log("size :" + image.size);
            //   console.log("height :" + e.path[0].height);
            //   console.log("width :" + e.path[0].width);
            // };
          };
        });
      },
      async fnUploadHeadShot() {
        try {
          this.loading = true;
          let base64 = this.previewImage;
          base64 = base64.slice(base64.indexOf(',') + 1); // 去掉轉為base64後開頭的圖片資訊
          let requestData = {
            image: base64,
          };
          const { data: APIdata } = await APIuploadHeadshot(requestData);
          if (APIdata.result === 1) {
            this.$bvModal.hide('uploadImageToggle');
            this.$store.dispatch('setHeadshotImage', this.previewImage); //上傳成功後同步將圖片暫時改為base64呈現，即時性
            this.$store.dispatch('setHeadshotDefault', false); //上傳成功後同步將圖片暫時改為base64呈現，即時性
            this.$bvModal.show('uploadImageSuccess');
          }
        } catch (error) {
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            this.$bvModal.show('setsomethingWrong');
          }
        } finally {
          this.loading = false;
          this.upLoadButtonToggle = false;
        }
      },
      clearUploadImage() {
        this.$bvModal.hide('uploadImageToggle');
        this.previewImage = this.vxHeadshotImage;
        this.upLoadButtonToggle = false;
        this.upLoadError = false;
      },
    },
    computed: {
      vxHeadshotImage() {
        return this.$store.state.headshotImage;
      },
      vxSignInToggle() {
        return this.$store.state.signInToggle;
      },
      onlyEnToggle() {
        // 等到某天需要多國語系時，這邊務必要檢查
        let array = [
          'Home',
          'DeviceManagement',
          'ListAllUsers',
          'bt2200-record-bt',
          'bt2200-record-st',
          'bt2300plus-record-bt',
          'bt2300plus-record-st',
          'btw300-record-bt',
          'btw300-record-st',
          'btw200-record-bt',
          'btw200-record-st',
          'btw200-record-ivt',
          'btw200-record-vm',
          'bt2400-record-bt',
          'bt2400-record-st',
          'bt2400-record-ivt',
          'bt2400-napa-record-bt',
          'bt2400-napa-record-st',
          'bt2400-napa-record-ivt',
          'bt2400-napa-record-lt',
        ];
        let toggle = false;
        if (this.$store.state.language !== 'en') {
          array.forEach(el => {
            if (this.$route.name === el) {
              toggle = true;
            }
          });
        }
        return toggle;
      },
      subdomain() {
        // Extract subdomain from the URL
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];
        return subdomain.toLowerCase();
      },
    },
  };
</script>

<style lang="scss">
  // #region [css reset]
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  button {
    border: none;
  }
  a:hover {
    text-decoration: none;
  }
  // #endregion

  .gap {
    height: 56px;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }

  body {
    background: $background;
  }

  #sidebar {
    color: $sidebar_color;
    position: fixed;
    height: 100%;
    z-index: 15;
  }

  .overflowToggle {
    overflow-y: auto;
  }
  // material-components-vue
  @import 'material-components-vue/dist/button/styles';
  // --------------------------
  *,
  a {
    font-family: $font-family;
  }

  .red {
    color: red;
  }

  .inputButton {
    p {
      background: #fff;
      border-radius: 5px;
      padding: 8px 12px;
      cursor: pointer;
      border: solid 1px #ddd;
    }
  }
  .disabledButton {
    display: none;
  }
</style>
